/* eslint-disable */
import { useEffect, useState } from 'react';
function Instruction() {
    return (
        <div className='instruction-container'>
            <h4>Instruction need to follow to use MsgMee App.</h4>
            <ul class="list-group instruction-list">
                <li class="list-group-item">Use your sociomee number to login.</li>
                <li class="list-group-item">Sync your sociomates by clicking on <code> <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg></code> then click on Sync sociomates.</li>
                <li class="list-group-item">Navigate to your contacts and start chatting.</li>
            </ul>
        </div>
    )
}

export default Instruction
