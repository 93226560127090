import axios from 'axios';
import Config from '../config';

const findByPhone = (data) => {
  console.log(data);
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/check-user-by-phone`,
    data,
  });
};

export default findByPhone;
