/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import qrOptions from './qroptions';
import io from 'socket.io-client';
import config from 'src/config';
import jwtDecode from 'jwt-decode';
import { v4 as uuid } from 'uuid';

let deviceId = uuid();
let deviceDetail = "Chrom";
function LoginWithQr({ setLoginScreen, loginFromQrResponse }) {
    const qrContainer = useRef(null);
    const [isSocketNeeded, setSocketNeeded] = useState(true);
    useEffect(() => {
         const socket = io(config.url ,{
            extraHeaders: {
              'Access-Control-Allow-Origin': '*',
            },
          });
        // Check if the code is running in the browser environment
        if (typeof window !== 'undefined') {
            
             // Replace with your server URL
            // Listen for the 'device-id' event to get the device ID
            socket.on('connect', ()=>{
                console.log("device ID : " + deviceId);
                socket.emit('joinLoginRoom', deviceId);
                
              });
              socket.on('disconnect',()=>{
                console.log("disconnected");
                if(isSocketNeeded){
                    socket.connect();
                }else{socket.disconnect();}
                
              });
            socket.on('msgmee-qr-login-response', (data) => {
              if(data.success){
                const token = data.token;
                setSocketNeeded(false);
                loginFromQrResponse(token,deviceId);
              }
            });
          
            import('styled-qr-code')
                .then((module) => {
                    // Use the imported module here
                    const QRCodeStyling = module.default;
                    const qrCode = new QRCodeStyling(qrOptions);
                    console.log('qrCode', qrCode, qrContainer.current)
                    if (qrContainer.current)
                        qrCode.append(qrContainer.current);
                    qrCode.update({
                        data: JSON.stringify({ deviceId , deviceDetail })
                    });
                })
                // const qrUrl = await QRCode.toDataURL(JSON.stringify({deviceId: randomUUID()}), {});
                .catch((error) => {
                    console.error('Failed to import qr-code-styling:', error);
                });
        }
         return () => {
            // Disconnect the socket when the component unmounts
            //socket.disconnect();
          };
    }, []);

    const handleLoginScreeChange = (isQr) => {
        console.log(isQr);
        setLoginScreen(isQr)
    }


    return (
        <div className="uk-container msgmee-login-container">
            <h1 class="uk-text-center uk-text-grey uk-text-bold">Welcome to MsgMee</h1>
            <form className="toggle-class login-form-phone">

                    <div className='magmee-login-fields-container'>
                        <div className="uk-text-center uk-text-small uk-text-muted">
                            Scan from msgmee mobile app where you are logged into your account.
                        </div>
                        <div className="uk-flex uk-flex-center" ref={qrContainer}></div>

                        <div className="uk-margin-small login-or">
                            <label>
                                OR
                            </label>
                        </div>
                        <div className="uk-margin-bottom">
                            <button type="button" className="uk-button uk-button-primary uk-border-square uk-width-1-1 get-otp-btn" onClick={() => handleLoginScreeChange(true)}>
                                Login With Phone
                            </button>
                        </div>
                    </div>
                    <div className="uk-text-center uk-text-muted login-term-text">
						<p className="uk-text-small uk-text uk-text-center">Sign up acknowledges our  <a href="https://www.sociomee.com/terms-conditions/" target="_blank" className="uk-link-muted uk-font uk-underline">Terms &amp; Conditions.</a></p>
						<p className="uk-text-small uk-text uk-text-center">Learn about data processing in our <a href="https://www.sociomee.com/privacy-policy/" target="_blank" className="uk-link-muted uk-font uk-underline">Privacy Policy</a> and <a href="https://www.sociomee.com/cookie-policy/" target="_blank" className="uk-link-muted uk-font uk-underline">Cookies Policy.</a></p>
					</div>
                
            </form>
        </div>
    );
}

export default LoginWithQr
