import axios from 'axios';
import Config from '../config';

const loginWithSociomeeToken = (token) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/verify-token`,
    data: { token },
  });
};

export default loginWithSociomeeToken;
