/* eslint-disable */
import React, { useState } from 'react';
import './phone.css';
import PhoneInput from 'react-phone-input-2'; // Import the phone input component

const PhoneInputComponent = ({setPhone}) => {
    const [phone, setdPhone] = useState('');
    const handlePhoneChange = (value, country) => {
      setPhone(value);
    };
  
    return (
      <div>
        <PhoneInput
          country={'in'} // Set the default country (e.g., United States)
          value={phone}
          onChange={handlePhoneChange}
          inputProps={{
            name: 'phone',
            required: true,
          }}
          inputStyle={{ width: '100%' }} // Adjust the input width as needed
        />
      </div>
    );
  };
  
  export default PhoneInputComponent;
  