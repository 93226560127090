/* eslint-disable */

import { useState } from 'react';
import Input from './Input';
import OTPInput from './OtpInput';
import PhoneInputComponent from './PhoneInputConponent';

function LoginWithPhone({ setPhone, setKeep, onSendOtp, onLogin, loginInfo, keep, isOtpScreen, setOtpScreen ,setLoginScreen , otp , setOTP , onSubmitOtp}) {
	
	
	const handleChangeNumber = ()=>{
		setOTP(['', '', '', '', '', '']);
		setOtpScreen(false);
	}
	const handleLoginScreeChange = (isQr)=>{
		console.log(isQr);
		setLoginScreen(isQr)
	}
	return (
		<div className="toggle-credits msgmee-login-container">
			<h2 class="uk-text-center uk-text-grey uk-text-bold">Welcome to MsgMee</h2>
			{isOtpScreen ? <p className='uk-text-center'> OTP sent successfully, Please enter OTP to verify.
</p> : <p className='uk-text-center'>  Select a country and use your sociomee number.
</p>}
			<form className="toggle-class login-form-phone" onSubmit={ isOtpScreen ? onSubmitOtp : onSendOtp}>
				
					{isOtpScreen ? <div className='msgmee-otp-fields'>	
						<div className="otp-label uk-margin-bottom">
						<h4 className='uk-text'>Enter OTP</h4>
						 <h5 onClick={()=>{handleChangeNumber();}}>Change number ? </h5>
						</div>
						<OTPInput
							otp={otp}
							setOTP={setOTP}
						/>

						<div className="uk-margin-bottom uk-margin-top">
							<button type="submit" className="uk-button uk-button-primary uk-border-square uk-width-1-1 get-otp-btn">
								VERIFY
							</button>
						</div>
					</div> : <div className='msgmee-login-fields-container'>
						
                        <PhoneInputComponent setPhone={setPhone}/>

						{loginInfo}

						<div className="uk-margin-bottom uk-margin-top">
							<button type="submit" className="uk-button uk-button-primary uk-border-square uk-width-1-1 get-otp-btn">
								GET OTP
							</button>
						</div>
						<div className="uk-margin-small login-or">
							<label>
								OR
							</label>
						</div>
						<div className="uk-margin-bottom">
							<button type="button" className="uk-button uk-button-primary uk-border-square uk-width-1-1 get-otp-btn" onClick={()=>handleLoginScreeChange(false)}>
								Login With QR
							</button>
						</div>
					</div>}
					<div className="uk-text-center uk-text-muted login-term-text">
						<p className="uk-text-small uk-text uk-text-center">Sign up acknowledges our  <a href="https://www.sociomee.com/terms-conditions/" target="_blank" className="uk-link-muted uk-font uk-underline">Terms &amp; Conditions.</a></p>
						<p className="uk-text-small uk-text uk-text-center">Learn about data processing in our <a href="https://www.sociomee.com/privacy-policy/" target="_blank" className="uk-link-muted uk-font uk-underline">Privacy Policy</a> and <a href="https://www.sociomee.com/cookie-policy/" target="_blank" className="uk-link-muted uk-font uk-underline">Cookies Policy.</a></p>
					</div>
				
			</form>
		</div>
	);
}

export default LoginWithPhone
