import axios from 'axios';
import Config from '../config';

const sendOtp = (phone) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/send-otp`,
    data: { phone },
  });
};

export default sendOtp;
