/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import './OTPInput.css'; // Import your custom CSS styles

const OTPInput = ({otp , setOTP}) => {
 
  const inputRefs = useRef([...Array(6)].map(() => React.createRef()));

  // Handle input change for individual OTP fields
  const handleInputChange = (index, value) => {
    if (/^[0-9]$/.test(value) && index >= 0 && index < 6) {
      // Only allow numeric input and update the OTP array
      const updatedOTP = [...otp];
      updatedOTP[index] = value;
      setOTP(updatedOTP);

      // Move focus to the next input field
      if (index < 5 && value !== '') {
        inputRefs.current[index + 1].current.focus();
      }
    }
  };

 // Handle backspace key press to delete OTP digits one by one
const handleBackspace = (index, event) => {
    if (event.keyCode === 8 && index > 0) {
      // Backspace key pressed and not in the first field
      const updatedOTP = [...otp];
      updatedOTP[index - 1] = ''; // Clear the previous field
      setOTP(updatedOTP);
      inputRefs.current[index - 1].current.focus(); // Move focus to the previous field
    }
  };

  // Add event listener for keydown when the component mounts
  useEffect(() => {
    const handleKeyDown = (event) => {
      for (let i = 5; i >= 0; i--) {
        if (document.activeElement === inputRefs.current[i].current) {
          handleBackspace(i, event);
          break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="">
      <div className="custom-otp-input">
        <div className="fields-row">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="tel"
              value={digit}
              onChange={(e) => handleInputChange(index, e.target.value)}
              maxLength="1"
              ref={inputRefs.current[index]} // Assign the ref
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OTPInput;
