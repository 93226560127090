/* eslint-disable */
import React from 'react'
import OtpInput from 'react-otp-input';
import './test.css';

function TestComponent() {
    return (
        <>Hello</>
    )
}

export default TestComponent
