/* eslint-disable */
import React from 'react'

function VerifyOtp() {
    return (
        <div className=''>

        </div>
    );
}

export default VerifyOtp
