/* eslint-disable */
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import Div100vh from 'react-div-100vh';
import Credits from './components/Credits';
import Config from '../../config';
import login from '../../actions/login';
import sendOtp from '../../actions/sendOtp';
import setAuthToken from '../../actions/setAuthToken';
import initIO from '../../actions/initIO';
import getInfo from '../../actions/getInfo';
import './style.css';
import LoginWithPhone from './components/LoginWithPhone';
import VerifyOtp from './components/VerifyOtp';
import LoginWithQr from './components/LoginWithQr';
import verifyOtp from 'src/actions/verifyOtp';
import Instruction from './components/Instruction';
import loginWithSociomeeToken from 'src/actions/loginWithSociomeeToken';
let currentUrl = window.location.href;
function Login({isFirst}) {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [Phone, setPhone] = useState('');
  const [keep, setKeep] = useState(true);
	const [isLoginScreen, setLoginScreen] = useState(true);
  const [isOtpScreen, setOtpScreen] = useState(false);
  const [loginErrors, setLoginErrors] = useState({});
  const setToken = useGlobal('token')[1];
  const setUser = useGlobal('user')[1];
  const isLogged = useGlobal('token')[0];
  const [entryPath, setEntryPath] = useGlobal('entryPath');
  const [otp, setOTP] = useState(['', '', '', '', '', '']); // Initialize an array with 6 empty strings for 6 digits
  const navigate = useNavigate();
  const [info, setInfo] = useState({});

  useEffect(()=>{
    console.log("listening for token");
    const url = new URL(currentUrl);
    const token = url.searchParams.get("token");
    console.log(token);
    if(token){
      if(isFirst){
      loginUserWithSociomeeToken(token);
      isFirst = false;
      }
    }
  },[currentUrl]);
  
  useEffect(() => {
   
    getInfo().then((res) => {
      setInfo(res.data);
    });
  }, []);
  
  const loginUserWithSociomeeToken = async(token)=>{
    try {
      const res = await loginWithSociomeeToken(token);
      console.log(res);
      if (res.status === 200) {
        if(res.data.status){
          if (keep) localStorage.setItem('token', res.data.data);
          if (keep) localStorage.setItem('user', JSON.stringify(jwtDecode(res.data.data)));
          setLoginErrors({});
          setAuthToken(res.data.data);
          setUser(jwtDecode(res.data.data));
          setToken(res.data.data);
          dispatch(initIO(res.data.data));
          currentUrl=window.location.host;
          navigate(['/login', '/'].includes(entryPath) ? '/' : entryPath, { replace: true });
          await setEntryPath(null);
          addToast(`Verified successfully!!`, {
            appearance: 'success',
            autoDismiss: true,
          });
         
        }else{
          console.log(res.data.message);
          addToast(`OTP do not match, please provide correct OTP!`, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    } catch (e) {
      console.log(e);
      let errors = {};
      if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
      else errors = e.response.data;
      setLoginErrors(errors);
      console.log(e);
    }
  }
  const onSendOtp = async (e) => {
    
    e.preventDefault();
    if(Phone.length < 8){
      console.log("Invalid or Empty phone number.");
      addToast(`Invalid or Empty phone number!!`, {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    try {
      const res = await sendOtp(Phone);
      console.log(res);
      if (res.status === 200) {
        if (res.data.status) {
					setOtpScreen(true);
          console.log(res.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onLogin = async () => {return;}


  const onSubmitOtp = async (e) => {
    e.preventDefault();
    console.log(info);
    const userotp = otp.join('');
    if(userotp.length !== 6){
       console.log("Invalid OTP.");
       addToast(`Please fill all fields.`, {
        appearance: 'error',
        autoDismiss: true,
      });
       return;
    }
    console.log(userotp);
   
    try {
      const res = await verifyOtp(Phone,userotp);
      console.log(res);
      if (res.status === 200) {
        if(res.data.status){
          if (keep) localStorage.setItem('token', res.data.data);
          if (keep) localStorage.setItem('user', JSON.stringify(jwtDecode(res.data.data)));
          setLoginErrors({});
          setAuthToken(res.data.data);
          setUser(jwtDecode(res.data.data));
          setToken(res.data.data);
          dispatch(initIO(res.data.data));
          navigate(['/login', '/'].includes(entryPath) ? '/' : entryPath, { replace: true });
          await setEntryPath(null);
          addToast(`Verified successfully!!`, {
            appearance: 'success',
            autoDismiss: true,
          });
        }else{
          console.log(res.data.message);
          addToast(`OTP do not match, please provide correct OTP!`, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    } catch (e) {
      console.log(e);
      let errors = {};
      if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
      else errors = e.response.data;
      setLoginErrors(errors);
      console.log(e);
    }
  };

  const loginInfo = Object.keys(loginErrors).map((key) => (
    <div className="uk-text-center" key={key}>
      <span className="uk-text-danger">{loginErrors[key]}</span>
    </div>
  ));
 const loginFromQrResponse = async (token , deviceId)=>{
  if(isLogged){
     return;
  }
  if (keep) localStorage.setItem('token', token);
  if (keep) localStorage.setItem('user', JSON.stringify(jwtDecode(token)));
  localStorage.setItem('deviceId', deviceId)
  setLoginErrors({});
  setAuthToken(token);
  setUser(jwtDecode(token));
  setToken(token);
  dispatch(initIO(token));
  navigate(['/login', '/'].includes(entryPath) ? '/' : entryPath, { replace: true });
  await setEntryPath(null);
  addToast(`Logged in successfully!!`, {
    appearance: 'success',
    autoDismiss: true,
  });
 }
  return (
    <main>
      <div className='parent-login-wrapper'>
      <Instruction />
      <div className='shrink-balancer'></div>
      { isLoginScreen ? <LoginWithPhone otp={otp} onSubmitOtp={onSubmitOtp} setOTP={setOTP} setLoginScreen={setLoginScreen} isOtpScreen={isOtpScreen} setOtpScreen={setOtpScreen} setPhone={setPhone} setKeep={setKeep} onSendOtp={onSendOtp} onLogin={onLogin} loginInfo={loginInfo} keep={keep} /> : <LoginWithQr loginFromQrResponse={loginFromQrResponse} setLoginScreen={setLoginScreen}/>}
      </div>
    </main>
  );
}

export default Login;
