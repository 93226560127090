/* eslint-disable */
import React, { useEffect, useState } from 'react';
import './syncmodal.css';
import loadergif from '../assets/loader.gif';
import userPlaceholder from "../assets/placeholder.jpg";
import checkForSocioMee from 'src/actions/checkForSocioMee';
import socioMeeAuth from 'src/actions/socioAuth';
import { useToasts } from 'react-toast-notifications';
import PhoneInputComponent from 'src/pages/Login/components/PhoneInputConponent';
import findByPhone from 'src/actions/findByPhone';
import createRoom from 'src/actions/createRoom';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { useDispatch } from 'react-redux';
import Actions from 'src/constants/Actions';

function UserModal({ isOpen, closeModal, children }) {

  const { addToast } = useToasts();
  const [loader, setLoaderVisible] = useState(false);
  const [user, setUser] = useState(null);
  const [Phone, setPhone] = useState('');
  const setNav = useGlobal('nav')[1];

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();




  const handleLogin = async () => {
    if (Phone === '') {
      addToast(`Please enter phone/mobile first.`, {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }


    const response = await findByPhone({ phone: Phone });
    console.log(response);
    if (response.status === 200) {
      if(response.data.status){
        setUser(response.data.user);
        
      }else{
        addToast(`User not found on msgmee..`, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }else{
      addToast(`Something went wrong..`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }

  };
  const getProfileImage = (profileImage) => {
    if (profileImage.includes("https://")) {
      return profileImage;
    } else {
      return "https://sociomee-dev.s3.ap-south-1.amazonaws.com/" + profileImage;
    }
  }
  
  const chat = async () => {
    console.log('clicked');
    const res = await createRoom(user._id);
    setNav('rooms');
    const target = `/room/${res.data.room._id}`;
    dispatch({ type: Actions.SET_ROOM, room: res.data.room });
    dispatch({ type: Actions.SET_MESSAGES, messages: res.data.room.messages });
    if (location.pathname !== target) navigate(target, { replace: true });

     closeModal();
  };

  return (
    <div id="">
      <div className="uk-container msg-sync-modal-container">
        {/* First row: Loader GIF in center */}
        {loader && <div className="uk-flex uk-flex-center uk-margin-bottom">
          <img src={loadergif} alt="Loader" className='sync-loader' />
        </div>}


        <div className="uk-flex uk-flex-start uk-margin-bottom uk-margin-top">
          <div className="uk-flex uk-flex-column uk-margin-left" style={{ minWidth: '300px' }}>

            <h3 className="uk-text-center uk-flex">Start new chat.</h3>

            {user ? <div className="uk-flex uk-flex-start uk-margin-bottom uk-margin-top" style={{ minWidth: '300px' }}>
              <div className="uk-text-center uk-flex uk-flex-row">
                <img src={getProfileImage(user.otherProfileImage) ?? ""} alt="User" className="uk-border-circle userimage" />
                <div className="uk-flex uk-flex-column uk-margin-left" style={{ marginLeft: '10px', marginTop: '10px' }}>
                  <p className="uk-margin-remove-top" style={{ marginBottom: '5px' }}>{user.fullName}</p>
                  <p className="uk-margin-remove-top">{"@" + user.username}</p>
                </div>
              </div>

            </div> : <PhoneInputComponent setPhone={setPhone} />}
            <div className='uk-flex uk-flex-center uk-margin-bottom uk-margin-top'>
              {!user && <button className="uk-button uk-button-primary msg-primary-button" onClick={handleLogin}>Find</button>}
              {user && <button className="uk-button uk-button-primary msg-primary-button" onClick={chat}>Start</button>}
              <button className="uk-button uk-button-default msg-close-button uk-margin-left" onClick={closeModal}>Close</button>
            </div>
          </div>
        </div>




      </div>
    </div>
  );
}

export default UserModal;
