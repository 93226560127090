import axios from 'axios';
import Config from '../config';

const verifyOtp = (phone, otp) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/verify-otp`,
    data: { phone, otp },
  });
};

export default verifyOtp;
