import axios from 'axios';
import Config from '../config';

const syncSociomates = (token) => {
  console.log(token);
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/sociomee_sync`,
    data: token,
  });
};

export default syncSociomates;
