import axios from 'axios';
import Config from '../config';

const checkForSocioMee = ({ token }) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/check_sociomee`,
    data: { token },
  });
};

export default checkForSocioMee;
