import axios from 'axios';
import Config from '../config';

const socioMeeAuth = (mydata) => {
  console.log(mydata);
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/sociomee_auth`,
    data: mydata,
  });
};

export default socioMeeAuth;
